exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-atomiki-js": () => import("./../../../src/pages/atomiki.js" /* webpackChunkName: "component---src-pages-atomiki-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oikogeneiaki-js": () => import("./../../../src/pages/oikogeneiaki.js" /* webpackChunkName: "component---src-pages-oikogeneiaki-js" */),
  "component---src-pages-omadiki-js": () => import("./../../../src/pages/omadiki.js" /* webpackChunkName: "component---src-pages-omadiki-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-symvouleutiki-js": () => import("./../../../src/pages/symvouleutiki.js" /* webpackChunkName: "component---src-pages-symvouleutiki-js" */),
  "component---src-pages-zeugous-js": () => import("./../../../src/pages/zeugous.js" /* webpackChunkName: "component---src-pages-zeugous-js" */)
}

